/* eslint-env browser */

import jquery from 'jquery'
import { drawAudio, draw, normalizeData, filterData } from './drawAudio'

import 'bulma/css/bulma.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'spinkit/spinkit.min.css'
import './main.css'

const $ = window.$ = window.jQuery = jquery

async function main () {
  // 0. 佈置 ui
  $('.upload-file-wrapper .loading').hide()

  // 1. 先 fetch
  const fieldsResult = await fetch('/api/fields')
    .then((response) => {
      return response.json()
    })

  console.log(fieldsResult)
  $('.upload-file').append(`
    <form class='upload-form' action="https://${fieldsResult.bucket}.storage.googleapis.com" method="post" enctype="multipart/form-data">
      <input type="hidden" name="key" value="${fieldsResult.key}">
      <input type="hidden" name="bucket" value="${fieldsResult.bucket}">
      <input type="hidden" name="GoogleAccessId" value="${fieldsResult.GoogleAccessId}">
      <input type="hidden" name="policy" value="${fieldsResult.policy}">
      <input type="hidden" name="signature" value="${fieldsResult.signature}">
      <input type="hidden" name="Content-Type" value="${fieldsResult['Content-Type']}">

      <label class="file-label">
        <input class="file-input upload-input" name="file" type="file" accept="audio/mpeg, audio/ogg, audio/*" />
        <span class="file-cta">
          <span class="file-icon">
            <i class="fas fa-upload"></i>
          </span>
          <span class="file-label">
            選取音頻檔上傳到 Google 分析字幕…
          </span>
        </span>
      </label>
    </form>
  `)

  $('.upload-input').on('change', async e => {
    const input = e.target
    if ('files' in input && input.files.length > 0) {
      $('.upload-form').submit()
      $('.upload-fil').hide()
      $('.upload-file-wrapper .loading').show()
      // await waitFileUpload()
    }
  })
}

main()

// $('.file-input').on('change', e => {
//   const input = e.target
//   if ('files' in input && input.files.length > 0) {
//     const file = input.files[0]
//     drawAudio(file)
//   }
// })

// var resizeTimer
// $(window).on('resize', function (e) {
//   clearTimeout(resizeTimer)
//   resizeTimer = setTimeout(function () {
//     if (!buffer) return
//     draw(normalizeData(filterData(buffer)))
//   }, 250)
// })
