/* eslint-env browser */

window.AudioContext = window.AudioContext || window.webkitAudioContext
let audioContext
let buffer

/**
 * Retrieves audio from an external source, the initializes the drawing function
 */
export const drawAudio = file => {
  audioContext = new AudioContext()
  const reader = new FileReader()
  reader.filename = file.name
  const p = new Promise((resolve, reject) => {
    reader.onload = event => resolve(event.target.result)
    reader.onerror = error => reject(error)
    reader.readAsArrayBuffer(file)
  })
  p
    .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
    .then(audioBuffer => {
      buffer = audioBuffer
      draw(normalizeData(filterData(buffer)))
    })
}

/**
 * Filters the AudioBuffer retrieved from an external source
 * @param {AudioBuffer} audioBuffer the AudioBuffer from drawAudio()
 * @returns {Array} an array of floating point numbers
 */
export const filterData = audioBuffer => {
  const rawData = audioBuffer.getChannelData(0) // We only need to work with one channel of data
  const samples = 3396 // Number of samples we want to have in our final data set
  const blockSize = Math.floor(rawData.length / samples) // the number of samples in each subdivision
  const filteredData = []
  console.log('audioBuffer.duration', audioBuffer.duration)
  console.log('rawData.length', rawData.length)
  console.log('blockSize', blockSize)
  for (let i = 0; i < samples; i++) {
    const blockStart = blockSize * i // the location of the first sample in the block
    let sum = 0
    for (let j = 0; j < blockSize; j++) {
      sum = sum + Math.abs(rawData[blockStart + j]) // find the sum of all the samples in the block
    }
    filteredData.push(sum / blockSize) // divide the sum by the block size to get the average
  }
  return filteredData
}

/**
 * Normalizes the audio data to make a cleaner illustration
 * @param {Array} filteredData the data from filterData()
 * @returns {Array} an normalized array of floating point numbers
 */
export const normalizeData = filteredData => {
  console.log('Math.max(...filteredData)', Math.max(...filteredData))
  const multiplier = Math.pow(Math.max(...filteredData), -1)
  const result = filteredData.map(n => n * multiplier)
  console.log('Math.max(...result)', Math.max(...result))
  return result
}

/**
 * Draws the audio file into a canvas element.
 * @param {Array} normalizedData The filtered array returned from filterData()
 * @returns {Array} a normalized array of data
 */
export const draw = normalizedData => {
  console.log('draw')
  // set up the canvas
  const canvas = document.querySelector('canvas')
  const dpr = window.devicePixelRatio || 1
  canvas.width = canvas.offsetWidth * dpr
  canvas.height = canvas.offsetHeight * dpr
  const ctx = canvas.getContext('2d')
  ctx.scale(dpr, dpr)
  // ctx.translate(canvas.offsetWidth / 2, 0) // set Y = 0 to be in the middle of the canvas

  console.log('canvas.offseWidth', canvas.offsetWidth)

  // draw the line segments
  const height = canvas.offsetHeight / normalizedData.length
  console.log('height', height)
  for (let i = 0; i < normalizedData.length; i++) {
    const y = height * i
    let width = normalizedData[i] * canvas.offsetWidth * 0.9
    if (width < 0) {
      width = 0
    } else if (width > canvas.offsetWidth) {
      width = canvas.offsetWidth
    }
    drawLineSegment(ctx, y, height, width)
  }
}

/**
 * A utility function for drawing our line segments
 * @param {AudioContext} ctx the audio context
 * @param {number} x  the x coordinate of the beginning of the line segment
 * @param {number} height the desired height of the line segment
 * @param {number} width the desired width of the line segment
 * @param {boolean} isEven whether or not the segmented is even-numbered
 */
export const drawLineSegment = (ctx, y, height, width) => {
  ctx.lineWidth = 2 // how thick the line is
  ctx.strokeStyle = '#fff' // what color our line is
  ctx.beginPath()
  ctx.moveTo(0, y)
  ctx.lineTo(width, y)
  ctx.arc(width, y + height / 2, height / 2, Math.PI * 1.5, Math.PI * 0.5, false)
  ctx.lineTo(0, y + height)
  ctx.stroke()
  ctx.fillStyle = 'blue'
  ctx.fill()
}
